import React from 'react';
import IndexView from 'views/IndexView';
import { SEO } from 'components/seo';

const HomePage = (): JSX.Element => {
  return <IndexView />;
};

export default HomePage;

export const Head = () => <SEO />;
