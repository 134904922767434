import React from 'react';

export function StructuredData({ data }: { data: { [k: string]: string } }) {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    ></script>
  );
}
